import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { AnimatedInit } from '../../../components/AnimatedInit';
import { CONDITION_DESKTOP } from '../../../constants/breakpoints';
import type { ItemType } from './types';

type StyledWrapperProps = {
  $isOdd: boolean;
};

const StyledWrapper = styled.div<StyledWrapperProps>(({ $isOdd }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& + &': {
    marginTop: 90,
  },

  [CONDITION_DESKTOP]: {
    flexDirection: $isOdd ? 'row' : 'row-reverse',

    '& + &': {
      marginTop: 120,
    },
  },
}));

type StyledTextsProps = {
  $isOdd: boolean;
};

const StyledTexts = styled.div<StyledTextsProps>(({ $isOdd }) => ({
  textAlign: 'center',

  [CONDITION_DESKTOP]: {
    textAlign: 'left',
    paddingRight: $isOdd ? 120 : 0,
    paddingLeft: $isOdd ? 0 : 120,
  },
}));

const StyledImageMobile = styled.img({
  width: '100%',
  maxWidth: 280,
  marginBottom: 30,

  [CONDITION_DESKTOP]: {
    display: 'none',
  },
});

const StyledImageDesktop = styled.img({
  display: 'none',

  [CONDITION_DESKTOP]: {
    width: 420,
    display: 'initial',
  },
});

const StyledTitle = styled.div({
  fontSize: 20,
  lineHeight: 1,
  fontWeight: 'bold',
  color: '#232832',
  marginBottom: 12,

  [CONDITION_DESKTOP]: {
    fontSize: 25,
    lineHeight: 1.2,
    marginBottom: 20,
  },
});

const StyledDescription = styled.h2({
  fontSize: 17,
  lineHeight: 1.47,
  fontWeight: 500,
  color: '#79818c',

  [CONDITION_DESKTOP]: {
    fontSize: 19,
    lineHeight: 1.58,
  },
});

type ItemProps = {
  item: ItemType;
  isOdd: boolean;
};

export const Item: FC<ItemProps> = ({
  item: { title, description, imageDesktop, imageMobile },

  isOdd,
}) => (
  <StyledWrapper $isOdd={isOdd}>
    <AnimatedInit>
      <StyledImageMobile src={imageMobile} alt="" />
    </AnimatedInit>

    <StyledTexts $isOdd={isOdd}>
      <AnimatedInit delayDesktop={isOdd ? 0 : 0.3}>
        <StyledTitle>{title}</StyledTitle>

        <StyledDescription>{description}</StyledDescription>
      </AnimatedInit>
    </StyledTexts>

    <AnimatedInit delayDesktop={isOdd ? 0.3 : 0}>
      <StyledImageDesktop src={imageDesktop} alt="" />
    </AnimatedInit>
  </StyledWrapper>
);
