import React, { memo } from 'react';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Container } from '../../../components/Container';
import { CONDITION_DESKTOP } from '../../../constants/breakpoints';
import { Item } from './Item';
import type { ItemType } from './types';

const StyledContent = styled.div({
  marginTop: 40,
  marginBottom: 40,

  [CONDITION_DESKTOP]: {
    marginTop: 90,
    marginBottom: 90,
  },
});

const StyledTitle = styled.div({
  fontSize: 24,
  lineHeight: '30px',
  fontWeight: 'bold',
  color: '#232832',
  marginBottom: 20,
  textAlign: 'center',

  [CONDITION_DESKTOP]: {
    fontSize: 40,
    lineHeight: '45px',
    marginBottom: 30,
  },
});

const StyledDescription = styled.h2({
  fontSize: 15,
  lineHeight: '25px',
  fontWeight: 500,
  color: '#79818c',
  marginBottom: 60,
  textAlign: 'center',
  maxWidth: 860,
  marginLeft: 'auto',
  marginRight: 'auto',

  [CONDITION_DESKTOP]: {
    fontSize: 19,
    lineHeight: 1.58,
  },
});

const StyledItemsWrapper = styled.div({
  marginTop: 60,

  [CONDITION_DESKTOP]: {
    marginTop: 90,
  },
});

const StyledButtonWrapper = styled.div({
  width: '70%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 60,
  textAlign: 'center',

  [CONDITION_DESKTOP]: {
    marginTop: 90,
  },
});

const StyledButtonTextWrapper = styled.div({
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 1.92,
  color: '#79818c',
  marginTop: 15,

  [CONDITION_DESKTOP]: {
    fontSize: 17,
    lineHeight: 1.76,
  },
});

type HowItWorksProps = {
  title: string;
  description?: string;
  items: ItemType[];
  buttonText?: string;
  buttonDescription?: string;
  onButtonClick?: () => void;
};

export const HowItWorks = memo<HowItWorksProps>(
  ({ title, description, items, buttonText, buttonDescription, onButtonClick }) => (
    <Container>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>

        {description && <StyledDescription>{description}</StyledDescription>}

        <StyledItemsWrapper>
          {items.map((item, index) => (
            <Item item={item} isOdd={index % 2 === 0} key={index} />
          ))}
        </StyledItemsWrapper>

        {(buttonText || buttonDescription) && (
          <StyledButtonWrapper>
            <Button
              type="button"
              $color="violet-gradient"
              isShadow
              $size="50"
              $block
              onClick={onButtonClick}
            >
              {buttonText}
            </Button>

            {buttonDescription && <StyledButtonTextWrapper>{buttonDescription}</StyledButtonTextWrapper>}
          </StyledButtonWrapper>
        )}
      </StyledContent>
    </Container>
  ),
);
