import { useWindowWidth } from '@react-hook/window-size';
import type { ReactNode } from 'react';
import React, { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP, DESKTOP } from '../constants/breakpoints';
import { Modal } from './Modal';

const StyledVideoButtonImage = styled.img({
  width: 115,
  borderRadius: 10,
  opacity: 0.65,
  transition: 'opacity 0.2s',
});

const StyledVideoButton = styled.div({
  position: 'relative',
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    [StyledVideoButtonImage]: {
      opacity: 0.9,
    },
  },

  [CONDITION_DESKTOP]: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
});

const StyledPlayImage = styled.img({
  position: 'absolute',
  left: '50%',
  marginLeft: -15,
  top: 18,
  zIndex: 1,

  [CONDITION_DESKTOP]: {
    left: 43,
    marginLeft: 0,
  },
});

const StyledVideoButtonText = styled.div({
  outline: 'none',
  width: 167,

  fontSize: 14,
  fontWeight: 500,
  lineHeight: 1.21,
  textAlign: 'center',
  color: '#ffffff',
  marginTop: 10,

  [CONDITION_DESKTOP]: {
    fontSize: 15,
    marginTop: 0,
    marginLeft: 15,
    textAlign: 'left',
  },
});

type VideoButtonProps = {
  url: string;
  children: ReactNode;
};

export const VideoButton = memo<VideoButtonProps>(({ url, children }) => {
  const windowWidth = useWindowWidth();
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const openVideo = useCallback(() => {
    setIsVideoOpen(true);
  }, []);

  const closeVideo = useCallback(() => {
    setIsVideoOpen(false);
  }, []);

  const [playerWidth, playerHeight] = useMemo<[number, number]>(() => {
    const width = Math.min(DESKTOP, windowWidth) - 60;

    const height = Math.round((width / 560) * 315);

    return [width, height];
  }, [windowWidth]);

  return (
    <>
      <StyledVideoButton role="button" tabIndex={0} onClick={openVideo}>
        <StyledPlayImage src="/landing/video-preview-play.svg" alt="" />

        <StyledVideoButtonImage src="/landing/preview.png" alt="" />

        <StyledVideoButtonText>{children}</StyledVideoButtonText>
      </StyledVideoButton>

      <Modal show={isVideoOpen} onHide={closeVideo} width={playerWidth}>
        <iframe
          title="Consulty"
          width={playerWidth}
          height={playerHeight}
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            verticalAlign: 'bottom',
          }}
        />
      </Modal>
    </>
  );
});
