import React, { memo } from 'react';
import styled from 'styled-components';

import { Container } from '../../../components/Container';
import { CONDITION_DESKTOP } from '../../../constants/breakpoints';
import { Answer } from './Answer';
import type { QuestionGroupType } from './types';

const StyledContent = styled.div({
  marginTop: 60,
  marginBottom: 90,

  [CONDITION_DESKTOP]: {
    marginTop: 120,
    marginBottom: 120,
  },
});

const StyledTitle = styled.div({
  fontSize: 24,
  lineHeight: '30px',
  fontWeight: 'bold',
  color: '#232832',
  marginBottom: 20,
  textAlign: 'center',

  [CONDITION_DESKTOP]: {
    fontSize: 40,
    lineHeight: '45px',
    marginBottom: 60,
  },
});

const StyledGroup = styled.div({
  '& + &': {
    marginTop: 30,
  },

  [CONDITION_DESKTOP]: {
    '& + &': {
      marginTop: 60,
    },
  },
});

const StyledGroupTitle = styled.div({
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: 1.11,
  color: '#222d39',
  marginBottom: 30,

  [CONDITION_DESKTOP]: {
    fontSize: 21,
    fontWeight: 500,
    lineHeight: 1.67,
    textAlign: 'center',
    marginBottom: 20,
  },
});

const title = 'Ответы на вопросы';

type AnswerProps = {
  questions: QuestionGroupType[];
};

export const Answers = memo<AnswerProps>(({ questions }) => (
  <Container>
    <StyledContent>
      <StyledTitle>{title}</StyledTitle>

      {questions.map(({ title: groupTitle, questions: childQuestions }, groupIndex) => (
        <StyledGroup key={groupIndex}>
          {groupTitle && <StyledGroupTitle>{groupTitle}</StyledGroupTitle>}

          {childQuestions.map(({ answer, question }, questionIndex) => (
            <Answer answer={answer} question={question} key={questionIndex} />
          ))}
        </StyledGroup>
      ))}
    </StyledContent>
  </Container>
));
