import { useIsInViewport } from '@wbe/use-is-in-viewport';
import type { ReactNode } from 'react';
import React, { memo, useRef } from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../constants/breakpoints';

type StyledWrapperProps = {
  $delayDesktop: number;
  $delayMobile: number;
};

const StyledWrapper = styled.div<StyledWrapperProps>(({ $delayDesktop, $delayMobile }) => ({
  position: 'relative',
  transition: 'all 0.8s',
  transitionDelay: `${$delayMobile}s`,

  [CONDITION_DESKTOP]: {
    transitionDelay: `${$delayDesktop}s`,
  },
}));

const visibleStyle = {
  bottom: 0,
  opacity: 1,
};

const hiddenStyle = {
  bottom: -50,
  opacity: 0,
};

type AnimatedInitProps = {
  className?: string;
  delayDesktop?: number;
  delayMobile?: number;
  children: ReactNode;
};

export const AnimatedInit = memo<AnimatedInitProps>(({ className, delayDesktop = 0, delayMobile = 0, children }) => {
  const rootRef = useRef<HTMLDivElement>(null);

  let isInViewport = false;

  if (process.browser && window) {
    isInViewport = useIsInViewport(rootRef as any, true, 70);
  }

  return (
    <StyledWrapper
      className={className}
      $delayDesktop={delayDesktop}
      $delayMobile={delayMobile}
      style={isInViewport ? visibleStyle : hiddenStyle}
      ref={rootRef}
    >
      {children}
    </StyledWrapper>
  );
});
