import styled from 'styled-components';

import { CONDITION_DESKTOP, DESKTOP, MOBILE } from '../constants/breakpoints';

export const Container = styled.div({
  boxSizing: 'border-box',
  minWidth: MOBILE,
  maxWidth: DESKTOP,
  paddingLeft: 20,
  paddingRight: 20,
  marginLeft: 'auto',
  marginRight: 'auto',

  [CONDITION_DESKTOP]: {
    width: DESKTOP,
    paddingLeft: 30,
    paddingRight: 30,
  },
});
