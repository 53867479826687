import type { FC } from 'react';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../constants/breakpoints';

const StyledWrapper = styled.div({
  borderBottom: 'solid 1px #eaeaea',
  paddingTop: 20,

  [CONDITION_DESKTOP]: {
    paddingTop: 25,
  },
});

const StyledQuestionBlock = styled.div({
  display: 'flex',
  outline: 'none',
  cursor: 'pointer',
});

const StyledQuestion = styled.div({
  flex: 1,
  paddingRight: 37,

  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: 1.25,
  color: '#232832',
  paddingBottom: 25,

  [CONDITION_DESKTOP]: {
    fontSize: 19,
  },
});

const StyledAnswer = styled.div({
  fontSize: 15,
  fontWeight: 500,
  lineHeight: 1.53,
  color: '#79818c',
  whiteSpace: 'pre-wrap',

  [CONDITION_DESKTOP]: {
    fontSize: 17,
    lineHeight: 1.47,
  },
});

const StyledAnswerContent = styled.div`
  a {
    color: #8b33ff;
  }
`;

type StyledIconProps = {
  $isOpen: boolean;
};

const StyledIcon = styled.div<StyledIconProps>(({ $isOpen }) => ({
  width: 20,
  height: 20,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundImage: $isOpen ? 'url(/landing/minus.svg)' : 'url(/landing/plus.svg)',

  [CONDITION_DESKTOP]: {
    width: 25,
    height: 25,
  },
}));

type AnswerProps = {
  question: string;
  answer: string;
};

const toggleOpen = (value: boolean): boolean => !value;

export const Answer: FC<AnswerProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (): void => {
    setIsOpen(toggleOpen);
  };

  return (
    <StyledWrapper>
      <StyledQuestionBlock role="button" tabIndex={0} onClick={toggle} onKeyPress={toggle}>
        <StyledQuestion>{question}</StyledQuestion>

        <StyledIcon $isOpen={isOpen} />
      </StyledQuestionBlock>

      <StyledAnswer
        style={{
          paddingBottom: isOpen ? 25 : 0,
        }}
      >
        <AnimateHeight height={isOpen ? 'auto' : 0}>
          <StyledAnswerContent dangerouslySetInnerHTML={{ __html: answer }} />
          {/* {answer} */}
        </AnimateHeight>
      </StyledAnswer>
    </StyledWrapper>
  );
};
