import { useAppDispatch } from 'hooks/redux';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { COOKIE_KEY } from 'shared/common/constants';
import { getCookie } from 'shared/helpers';
import { actions, selectors } from 'store/ducks';

export const withUserOnStaticPage = <T extends Record<string, unknown>>(Component: FC<T>) =>
  function withUserOnStaticPageComponent(props: T): JSX.Element {
    const dispatch = useAppDispatch();
    const isAuthenticate = useSelector(selectors.profile.selectIsAuthentication);

    useEffect(() => {
      if (!isAuthenticate) {
        const localToken = process.browser && getCookie(COOKIE_KEY.accessToken);
        if (localToken) {
          dispatch(actions.profile.setToken(localToken));
          dispatch(actions.profile.fetchMe());
          dispatch(actions.profile.fetchWallet());
          dispatch(actions.profile.fetchSelfPromocode());
          dispatch(actions.profile.fetchConsultationsWithoutReview());
        }
      }
    }, []);

    return <Component {...props} />;
  };
